<style scoped></style>

<template>
  <div style="display: flex;flex-direction: row;">
    <HandlePlan mode="exec" style="width: 250px; height: unset; flex: unset;" @item-choose="onChoosePlan" />
    <AppPage style="margin-left: 0; flex: 1; width: 0; height: unset;" @on-rows-change="onRowsChange" :page="pager.page" :limit="pager.limit" :total="pager.total" @on-page-change="onPageChange">
      <template v-slot:header>
        <Row type="flex" justify="space-between" :wrap="false" align="middle" style="margin-bottom: 10px;">
          <i-col style="flex: 1; width: 0;overflow-x: auto;white-space: nowrap;">
            <Row type="flex" align="middle">
              <i-col><fm-title title-text="货物处置"></fm-title></i-col>
              <i-col v-if="storehouseData"><Tag size="large" closable @on-close="$router.replace({ name: $route.name })">当前: {{ storehouseData.name }}</Tag></i-col>
            </Row>
          </i-col>
          <i-col>
            <Button v-if="$route.query.back" @click="$router.back()" style="margin-right: 10px;" icon="ios-arrow-back">返回</Button>
            <Button @click="refresh" :loading="loading.load" icon="md-refresh">刷新</Button>
          </i-col>
        </Row>
      </template>
      <template v-slot="{ contentHeight }">
        <Table :height="contentHeight" :data="dataList" :columns="columns" stripe :loading="loading.load">
          <template v-slot:goodsId="{ row }">
            <Tooltip>
              <Tag class="noborder" :color="row.goodsStatusData.color">
                {{ row.goodsName }} ({{ row.goodsUseTypeText }})
              </Tag>
              <template v-slot:content>
                <div>{{ row.goodsStatusData.label }}</div>
                <div>{{ row.goodsGgxh }}</div>
                <div>{{ row.goodsCode }}</div>
              </template>
            </Tooltip>
          </template>
          <template v-slot:planVolume="{ row }">
            {{ row.planVolume !== null ? (row.planVolume + ' ' + row.goodsUnit) : '-' }}
          </template>
          <template v-slot:currentVolume="{ row }">
            {{ row.currentVolume !== null ? (row.currentVolume + ' ' + row.goodsUnit) : '-' }}
          </template>
          <template v-slot:realVolume="{ row }">
            {{ row.realVolume !== null ? (row.realVolume + ' ' + row.goodsUnit) : '-' }}
          </template>
          <template v-slot:dealVolume="{ row }">
            {{ row.dealVolume !== null ? (row.dealVolume + ' ' + row.goodsUnit) : '-' }}
          </template>
          <template v-slot:storehouseId="{ row }">
            {{ row.storehouseName }} / {{ row.storehouseUnitName }}
          </template>
          <template v-slot:switchGoodsId="{ row }">
            <Tooltip v-if="row.switchGoodsId">
              <Tag class="noborder" :color="row.switchGoodsStatusData.color">
                {{ row.switchGoodsName }} ({{ row.switchGoodsUseTypeText }}) - {{ row.switchVolume }}{{ row.switchGoodsUnit }}
              </Tag>
              <template v-slot:content>
                <div>{{ row.switchGoodsStatusData.label }}</div>
                <div>{{ row.switchGoodsGgxh }}</div>
                <div>{{ row.switchGoodsCode }}</div>
              </template>
            </Tooltip>
          </template>
          <template v-slot:goodsHandleType="{ row }">
            <Tag class="noborder" v-if="row.goodsHandleTypeData" :color="row.goodsHandleTypeData.color">
              {{ row.goodsHandleTypeData.label }}
            </Tag>
            <Tag v-else>其他</Tag>
          </template>
          <template v-slot:status="{ row }">
            <Tag  class="noborder" v-if="!row.dealVolume" color="volcano">待处理</Tag>
            <Tag  class="noborder" v-else color="geekblue">完成</Tag>
          </template>
          <template v-slot:action="{ row }">
            <Button v-if="!row.dealVolume" :loading="loading.submit" @click="itemClick(row)" type="primary" size="small">处置</Button>
            <Poptip v-else transfer confirm @on-ok="itemDel(row)" title="确定撤销吗？">
              <Button :loading="loading.del" type="warning" size="small">撤销</Button>
            </Poptip>
          </template>
        </Table>
      </template>
      <template v-slot:footer>
        <Drawer placement="right" title="处置" :mask-closable="false" closable transfer draggable :width="30" v-model="status.form">
          <DrawerAction :loading="loading.submit" @confirm="submit" @cancel="status.form = false">
            <ExecForm :storehouseId="currentStorehouseId" ref="form" :data="formData" />
          </DrawerAction>
        </Drawer>
      </template>
    </AppPage>
  </div>
</template>

<script>
import AppPage from '../../components/page/base.vue'
import DrawerAction from '../../components/drawer/action.vue'
import request from '../../api/index'
import ExecForm from '../../components/handle/form/exec.vue'
import HandlePlan from '../../components/handle/plan.vue'

export default {
  components: { AppPage, ExecForm, DrawerAction, HandlePlan },
  props: {
    goodsHandleId: { type: Number, default: null }
  },
  data () {
    return {
      routerName: this.$route.name,
      pager: {
        page: 1,
        limit: 10,
        total: 0
      },
      query: {},
      loading: {
        load: false,
        submit: false,
        del: false
      },
      dataList: [],
      status: {
        form: false
      },
      formData: null,
      storehouseData: null,
      choosePlan: null
    }
  },
  computed: {
    currentStorehouseId () {
      const storehouseId = this.$route.query.storehouseId
      return storehouseId ? Number(storehouseId) : null
    },
    scmGoodsStatusList () {
      return this.$store.getters.scmGoodsStatusList
    },
    scmGoodsHandleTypeList () {
      return this.$store.getters.scmGoodsHandleTypeList
    },
    scmGoodsUseTypeList () {
      return this.$store.getters.scmGoodsUseTypeList
    },
    columns () {
      const columns = [
        { title: '物品', key: 'goodsId', slot: 'goodsId', fixed: 'left', minWidth: 180 },
        { title: '计划处置量', key: 'planVolume', slot: 'planVolume', minWidth: 130 },
        { title: '计划售卖金额', key: 'planSellAmount', minWidth: 130 },
        { title: '实际售卖金额', key: 'sellAmount', minWidth: 130 },
        { title: '货物位置', key: 'storehouseId', slot: 'storehouseId', minWidth: 200 },
        { title: '当前库存', key: 'currentVolume', slot: 'currentVolume', minWidth: 130 },
        { title: '实际处置量', key: 'realVolume', slot: 'realVolume', minWidth: 130 },
        { title: '已处置量', key: 'dealVolume', slot: 'dealVolume', minWidth: 130 },
        { title: '转换', key: 'switchGoodsId', slot: 'switchGoodsId', minWidth: 180 },
        { title: '备注', key: 'remark', ellipsis: true, tooltip: true, minWidth: 160 },
        { title: '处置类型', key: 'goodsHandleType', slot: 'goodsHandleType', fixed: 'right', minWidth: 100 },
        { title: '处置状态', key: 'status', slot: 'status', fixed: 'right', minWidth: 100 }
      ]
      if (this.$authFunsProxy.manage) {
        columns.push({ title: '操作', key: 'action', slot: 'action', fixed: 'right', width: 160 })
      }
      return columns
    }
  },
  watch: {
    currentStorehouseId () {
      if (this.routerName === this.$route.name) {
        this.loadStorehouseData()
        this.refresh()
      }
    }
  },
  methods: {
    async loadStorehouseData () {
      await this.$store.dispatch('loadStoreHouse')
      this.storehouseData = this.$store.getters.scmStoreHouseList.find(v => v.id === this.currentStorehouseId)
    },
    itemClick (item) {
      this.formData = item
      this.status.form = true
    },
    async itemDel (item) {
      this.loading.del = true
      try {
        await request.delete('/goods_handle/detail/handle/' + item.id)
        this.$Message.success('操作成功')
        this.loadData()
      } catch (e) {
        this.$Modal.error({ title: '系统提示', content: e.message })
      }
      this.loading.del = false
    },
    async submit () {
      try {
        const data = await this.$refs.form.getFormData()
        if (data === false) {
          return this.$Message.warning('请完整填写表单')
        }
        if (data === null || !this.formData.id) {
          return
        }

        this.loading.submit = true
        await request.post('/goods_handle/detail/handle/' + this.formData.id, data)
        this.$Message.success('操作成功')
        this.status.form = false
        this.loadData()
      } catch (e) {
        this.$Modal.error({ title: '系统提示', content: e.message })
      }
      this.loading.submit = false
    },
    refresh () {
      this.pager.page = 1
      this.loadData()
    },
    async onPageChange (page) {
      this.pager.page = page
      this.loadData()
    },
    async loadData () {
      if (!this.$authFunsProxy.get) {
        return
      }

      if (!this.choosePlan) {
        return
      }

      const params = {
        exeStatus: 1,
        goodsHandleId: this.choosePlan.id
      }

      if (this.currentStorehouseId) {
        params.storehouseId = this.currentStorehouseId
      }

      this.loading.load = true
      try {
        const res = await request.get('/goods_handle/detail', Object.assign(params, {
          pageNum: this.pager.page,
          pageSize: this.pager.limit
        }))

        res.data.forEach(item => {
          item.goodsStatusData = this.scmGoodsStatusList.find(v => v.value === item.goodsStatus)
          item.goodsUseTypeText = this.scmGoodsUseTypeList.find(v => v.value === item.goodsUseType).label
          item.goodsHandleTypeData = this.scmGoodsHandleTypeList.find(v => v.value === item.goodsHandleType)
          item.switchGoodsUseTypeText = item.switchGoodsUseType ? this.scmGoodsUseTypeList.find(v => v.value === item.switchGoodsUseType).label : null
          item.switchGoodsStatusData = this.scmGoodsStatusList.find(v => v.value === item.switchGoodsStatus)
        })

        this.pager.total = res.total
        this.dataList = res.data
      } catch (e) {
        this.$Modal.error({ title: '系统提示', content: e.message })
      }
      this.loading.load = false
    },
    onRowsChange (rows) {
      this.pager.page = 1
      this.pager.limit = rows
      this.loadData()
    },
    onChoosePlan (plan) {
      this.choosePlan = plan
      this.refresh()
    }
  },
  mounted () {
    this.loadStorehouseData()
  }
}
</script>
