<style scoped></style>

<template>
  <Form label-position="top" ref="form" :model="formData" :rules="rules">
    <FormItem label="实际处置数量" prop="volume">
      <InputNumber controls-outside v-model="formData.volume" />
    </FormItem>
    <FormItem label="实际售卖金额" prop="sellAmount">
      <InputNumber controls-outside v-model="formData.sellAmount" />
    </FormItem>
    <FormItem label="是否切换为其他物品">
      <i-switch v-model="isSwitch" />
    </FormItem>
    <template v-if="isSwitch">
      <FormItem label="计划转换物品" prop="switchGoodsId">
        <GoodsChoose v-model="formData.switchGoodsId" />
      </FormItem>
      <FormItem label="计划转换物品数量" prop="planSwitchGoodsVolume">
        <InputNumber controls-outside v-model="formData.planSwitchGoodsVolume" />
      </FormItem>
      <FormItem label="转换货物入库仓库" prop="storehouseId">
        <Select v-model="formData.storehouseId" placeholder="请选择仓库\车间">
          <Option v-for="item in scmStoreHouseList" :key="item.id" :value="item.id">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem v-if="formData.storehouseId" label="转换货物入库仓库单元" prop="storehouseUnitId">
        <UnitChoose :storehouseId="formData.storehouseId" v-model="formData.storehouseUnitId" />
      </FormItem>
    </template>
  </Form>
</template>

<script>
import GoodsChoose from '../../goods/base/choose.vue'
import UnitChoose from '../../stock/choose/unit.vue'

export function getDefault () {
  return {
    volume: null,
    switchGoodsId: null,
    switchGoodsVolume: null,
    storehouseId: null,
    storehouseUnitId: null,
    sellAmount: null,
  }
}

export default {
  components: { GoodsChoose, UnitChoose },
  props: {
    data: { type: Object, default: null },
    storehouseId: { type: Number, default: null }
  },
  data () {
    const validator = (rule, value, callback) => {
      if (this.isSwitch && value === null || value === undefined) {
        callback(new Error('不能为空'))
      } else {
        callback()
      }
    }

    const rules = Object.freeze({
      volume: [
        { required: true, message: '请输入数量' }
      ],
      sellAmount: [
        { required: true, message: '请输入金额' }
      ],
      switchGoodsId: [
        { validator }
      ],
      planSwitchGoodsVolume: [
        { validator }
      ],
      storehouseId: [
        { validator }
      ],
      storehouseUnitId: [
        { validator }
      ],
    })

    const isSwitch = this.data && this.data.switchGoodsId ? true : false
    
    const formData = JSON.parse(JSON.stringify(this.data)) || getDefault()
    if (formData.volume === undefined) {
      formData.volume = null
    }
    if (isSwitch && this.storehouseId && !formData.storehouseId) {
      formData.storehouseId = this.storehouseId
    }

    return {
      isSwitch,
      formData,
      rules
    }
  },
  watch: {
    data: {
      deep: true,
      handler () {
        const data = JSON.parse(JSON.stringify(this.data)) ? JSON.parse(JSON.stringify(this.data)) : getDefault()
        if (data.volume === undefined) {
          data.volume = null
        }
        this.formData = data
        this.isSwitch = this.data && this.data.switchGoodsId ? true : false
        if (this.isSwitch && !this.formData.storehouseId && this.storehouseId) {
          this.formData.storehouseId = this.storehouseId
        }
      }
    }
  },
  computed: {
    scmStoreHouseList () {
      return this.$store.getters.scmStoreHouseList
    }
  },
  methods: {
    getFormData () {
      return new Promise(resolve => {
        this.$refs.form.validate((valid) => {
          if (valid) {
            const data = JSON.parse(JSON.stringify(this.formData))
            if (!this.isSwitch) {
              delete data.goodsId
              data.switchGoodsId = null
              data.planSwitchGoodsVolume = null
              data.storehouseId = null
              data.storehouseUnitId = null
            }
            resolve(data)
          } else {
            resolve(false)
          }
        })
      })
    }
  },
  mounted () {
    this.$store.dispatch('loadStoreHouse')
  }
}
</script>
